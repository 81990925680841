var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "CCard",
    [
      _c(
        "CCardHeader",
        [
          _c("CIcon", { attrs: { name: "cil-chat-bubble" } }),
          _c("h5", { staticClass: "d-inline ml-2" }, [_vm._v("New Message")]),
          _c(
            "div",
            { staticClass: "card-header-actions" },
            [
              _c(
                "CButton",
                { attrs: { color: "dark", to: _vm.prevRoute } },
                [
                  _c("CIcon", {
                    staticClass: "align-bottom",
                    attrs: { name: "cil-arrow-thick-left" },
                  }),
                  _vm._v(" Back "),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "CCardBody",
        [
          !_vm.isValid
            ? _c("form-summary", {
                staticClass: "form-errors alert alert-danger mt-0",
                attrs: { validator: _vm.$v.form, attributes: _vm.attributes },
              })
            : _vm._e(),
          _c("CForm", { staticClass: "message-form" }, [
            _c("div", { staticClass: "d-flex flex-column" }, [
              _c(
                "div",
                { staticClass: "d-flex flex-column flex-md-row" },
                [
                  _c(
                    "div",
                    { staticClass: "form-group w-100 mr-2" },
                    [
                      _c("label", { staticClass: "d-block" }, [
                        _vm._v("Message Channels:"),
                      ]),
                      _c("v-select", {
                        attrs: {
                          placeholder: "Select..",
                          options: _vm.$globalVar.messageChannels,
                          reduce: (c) => c.value,
                          searchable: false,
                          disabled: _vm.form.segment == "Guest",
                        },
                        on: { input: _vm.getPushApps },
                        model: {
                          value: _vm.$v.form.channels.$model,
                          callback: function ($$v) {
                            _vm.$set(_vm.$v.form.channels, "$model", $$v)
                          },
                          expression: "$v.form.channels.$model",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("CSelect", {
                    staticClass: "w-100",
                    attrs: {
                      label: "Segment:",
                      placeholder: "Select..",
                      value: _vm.$v.form.segment.$model,
                      options: _vm.segments,
                    },
                    on: {
                      "update:value": function ($event) {
                        return _vm.$set(_vm.$v.form.segment, "$model", $event)
                      },
                    },
                  }),
                ],
                1
              ),
              _c("div", { staticClass: "d-flex flex-column flex-md-row" }, [
                _vm.channelHasPush
                  ? _c(
                      "div",
                      {
                        staticClass: "form-group w-100",
                        class: { "mr-2": _vm.form.segment === "Leads" },
                      },
                      [
                        _c("label", { staticClass: "d-block" }, [
                          _vm._v("Push Apps:"),
                        ]),
                        _c("v-select", {
                          attrs: {
                            placeholder: "Select..",
                            label: "label",
                            options: _vm.pushApps,
                            searchable: false,
                            disabled:
                              !_vm.channelHasPush ||
                              _vm.form.segment == "Guest",
                            multiple: "",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "option",
                                fn: function (app) {
                                  return [
                                    _c("strong", [
                                      _vm._v(
                                        _vm._s(
                                          /[A-Z]/.test(app.provider)
                                            ? app.provider
                                            : app.provider.toUpperCase()
                                        )
                                      ),
                                    ]),
                                    _vm._v(" - "),
                                    _c("span", [
                                      _vm._v(
                                        "(" +
                                          _vm._s(
                                            app.provider == "OneSignal"
                                              ? app.domain
                                              : app.app_id
                                          ) +
                                          ")"
                                      ),
                                    ]),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            692338402
                          ),
                          model: {
                            value: _vm.$v.form.apps.$model,
                            callback: function ($$v) {
                              _vm.$set(_vm.$v.form.apps, "$model", $$v)
                            },
                            expression: "$v.form.apps.$model",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.form.segment === "Leads"
                  ? _c(
                      "div",
                      { staticClass: "form-group w-100" },
                      [
                        _c("label", { staticClass: "d-block" }, [
                          _vm._v("Reference Restaurant for Leads:"),
                        ]),
                        _c("v-select", {
                          attrs: {
                            placeholder: "Select..",
                            label: "label",
                            options: _vm.restaurants,
                            reduce: (r) => r.value,
                            searchable: false,
                            disabled: _vm.form.segment !== "Leads",
                            loading: _vm.loading && _vm.restaurants.length == 0,
                          },
                          model: {
                            value: _vm.$v.form.data["restaurant_id"].$model,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.$v.form.data["restaurant_id"],
                                "$model",
                                $$v
                              )
                            },
                            expression: "$v.form.data['restaurant_id'].$model",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
              _c("section", { staticClass: "alert alert-dark p-2 my-3" }, [
                _c("div", { staticClass: "form-group w-100 mb-0" }, [
                  _c("h5", [_vm._v("Settings")]),
                  _vm.isApplicableCampaign
                    ? _c(
                        "div",
                        { staticClass: "form-group w-100" },
                        [
                          _c("label", { staticClass: "d-block" }, [
                            _vm._v("Use Voucher (optional):"),
                          ]),
                          _c(
                            "v-select",
                            {
                              attrs: {
                                placeholder:
                                  _vm.vouchers.length == 0
                                    ? "Search.."
                                    : "Select or search..",
                                options: _vm.vouchers,
                                label: "code",
                                searchable: true,
                                loading:
                                  _vm.loading && _vm.vouchers.length == 0,
                              },
                              on: { search: _vm.searchVouchers },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "option",
                                    fn: function (v) {
                                      return [
                                        _vm._v(" " + _vm._s(v.code) + " "),
                                        _c("br"),
                                        _c("cite", { staticClass: "small" }, [
                                          _vm._v(_vm._s(v.name)),
                                        ]),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                2816312414
                              ),
                              model: {
                                value: _vm.$v.form.voucher.$model,
                                callback: function ($$v) {
                                  _vm.$set(_vm.$v.form.voucher, "$model", $$v)
                                },
                                expression: "$v.form.voucher.$model",
                              },
                            },
                            [
                              _c(
                                "span",
                                {
                                  attrs: { slot: "no-options" },
                                  slot: "no-options",
                                },
                                [_vm._v("Type to search vouchers..")]
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.channelHasPush
                    ? _c("div", { staticClass: "form-group w-100" }, [
                        _c("label", { staticClass: "d-block" }, [
                          _vm._v("Custom Link (optional):"),
                        ]),
                        _c(
                          "div",
                          { staticClass: "d-flex flex-column flex-md-row" },
                          [
                            _c("CInput", {
                              staticClass: "w-100 mb-1 mb-md-0 mr-2",
                              attrs: {
                                placeholder: "URL",
                                value:
                                  _vm.$v.form.data["custom_link"]["url"].$model,
                              },
                              on: {
                                "update:value": function ($event) {
                                  return _vm.$set(
                                    _vm.$v.form.data["custom_link"]["url"],
                                    "$model",
                                    $event
                                  )
                                },
                              },
                            }),
                            _c("CInput", {
                              staticClass: "w-100 mb-0",
                              attrs: {
                                placeholder: "Title",
                                value:
                                  _vm.$v.form.data["custom_link"]["title"]
                                    .$model,
                              },
                              on: {
                                "update:value": function ($event) {
                                  return _vm.$set(
                                    _vm.$v.form.data["custom_link"]["title"],
                                    "$model",
                                    $event
                                  )
                                },
                              },
                            }),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                  _c("div", { staticClass: "d-flex flex-column flex-md-row" }, [
                    _c("div", { staticClass: "form-group w-100 mr-2" }, [
                      _c("label", { staticClass: "d-block" }, [
                        _vm._v("Put Inbox:"),
                      ]),
                      _c(
                        "div",
                        { staticClass: "d-flex flex-gap-3" },
                        [
                          _c("CSwitch", {
                            attrs: {
                              checked: _vm.$v.form.put_inbox.$model,
                              labelOn: "YES",
                              labelOff: "NO",
                              color: "success",
                              shape: "pill",
                            },
                            on: {
                              "update:checked": function ($event) {
                                return _vm.$set(
                                  _vm.$v.form.put_inbox,
                                  "$model",
                                  $event
                                )
                              },
                            },
                          }),
                          _vm.form.put_inbox
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex align-items-baseline flex-gap-1",
                                },
                                [
                                  _c("label", [_vm._v("Active Until:")]),
                                  _c("CInput", {
                                    attrs: {
                                      type: "date",
                                      value: _vm.$v.form.active_until.$model,
                                      min: new Date()
                                        .toISOString()
                                        .slice(0, 10),
                                    },
                                    on: {
                                      "update:value": function ($event) {
                                        return _vm.$set(
                                          _vm.$v.form.active_until,
                                          "$model",
                                          $event
                                        )
                                      },
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]),
                    _vm.channelHasPush
                      ? _c("div", { staticClass: "form-group w-100" }, [
                          _c("label", { staticClass: "d-block" }, [
                            _vm._v("In-App Message:"),
                          ]),
                          _c(
                            "div",
                            { staticClass: "d-flex flex-gap-3" },
                            [
                              _c("CSwitch", {
                                attrs: {
                                  checked: _vm.$v.form.data["in_app"].$model,
                                  labelOn: "YES",
                                  labelOff: "NO",
                                  color: "success",
                                  shape: "pill",
                                },
                                on: {
                                  "update:checked": function ($event) {
                                    return _vm.$set(
                                      _vm.$v.form.data["in_app"],
                                      "$model",
                                      $event
                                    )
                                  },
                                },
                              }),
                              _vm.form.data["in_app"] === true
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex align-items-end flex-gap-1",
                                    },
                                    [
                                      _c("CInputCheckbox", {
                                        attrs: {
                                          custom: "",
                                          label:
                                            "Do not send as Push Notification",
                                          checked:
                                            _vm.$v.form.data[
                                              "disable_push_notification"
                                            ].$model,
                                        },
                                        on: {
                                          "update:checked": function ($event) {
                                            return _vm.$set(
                                              _vm.$v.form.data[
                                                "disable_push_notification"
                                              ],
                                              "$model",
                                              $event
                                            )
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                  ]),
                ]),
              ]),
              _c(
                "div",
                { staticClass: "d-flex flex-column flex-md-row message" },
                [
                  !_vm.languages.length
                    ? _c("CSpinner", {
                        attrs: { color: "primary", size: "sm" },
                      })
                    : _c(
                        "CTabs",
                        _vm._l(_vm.languages, function (lang) {
                          return _c(
                            "CTab",
                            {
                              staticClass: "tab-item",
                              attrs: { title: lang.label },
                            },
                            [
                              _c("CInput", {
                                staticClass: "w-100 mb-2",
                                attrs: {
                                  placeholder: `${lang.label} title`,
                                  value: _vm.$v.form.title[lang.value].$model,
                                  isValid:
                                    lang.value === "nl"
                                      ? _vm.checkIfValid("title.nl")
                                      : null,
                                },
                                on: {
                                  "update:value": function ($event) {
                                    return _vm.$set(
                                      _vm.$v.form.title[lang.value],
                                      "$model",
                                      $event
                                    )
                                  },
                                },
                              }),
                              _c(
                                "div",
                                { staticClass: "form-group message-editor" },
                                [
                                  _c("editor", {
                                    attrs: {
                                      "api-key":
                                        "hr96yvu80sl0l683adp0offp2wyhhcsu2n23iwl38tvwed63",
                                      init: {
                                        plugins: "wordcount,visualchars,table",
                                        toolbar_mode: "sliding",
                                        toolbar:
                                          "undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | align lineheight | tinycomments | checklist numlist bullist indent outdent | emoticons charmap | removeformat",
                                        tinycomments_mode: "embedded",
                                      },
                                    },
                                    model: {
                                      value:
                                        _vm.$v.form.message[lang.value].$model,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.$v.form.message[lang.value],
                                          "$model",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "$v.form.message[lang.value].$model",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex align-items-center justify-content-between",
                                },
                                [
                                  _c(
                                    "CDropdown",
                                    {
                                      attrs: { color: "link", caret: false },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "toggler-content",
                                            fn: function () {
                                              return [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "d-flex flex-gap-1 align-items-center",
                                                  },
                                                  [
                                                    _c("CIcon", {
                                                      attrs: {
                                                        name: "cil-plus",
                                                      },
                                                    }),
                                                    _vm._v(" Add variable "),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                            proxy: true,
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    },
                                    _vm._l(_vm.variables, function (v) {
                                      return _c(
                                        "CDropdownItem",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.addVariable(
                                                lang.value,
                                                v.field
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v(" " + _vm._s(v.label) + " ")]
                                      )
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        }),
                        1
                      ),
                ],
                1
              ),
            ]),
          ]),
        ],
        1
      ),
      _c("CCardFooter", { staticClass: "sticky-bottom" }, [
        _c(
          "div",
          { staticClass: "d-flex align-items-center flex-gap-2" },
          [
            _c(
              "CDropdown",
              {
                attrs: { caret: "", split: "", color: "primary", size: "lg" },
                scopedSlots: _vm._u([
                  {
                    key: "toggler-content",
                    fn: function () {
                      return [
                        _c(
                          "div",
                          {
                            class: { "disable-item": _vm.sending },
                            on: {
                              click: function ($event) {
                                return _vm.send()
                              },
                            },
                          },
                          [_vm._v(" Send ")]
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              },
              [
                _c(
                  "CDropdownItem",
                  {
                    on: {
                      click: function ($event) {
                        _vm.showScheduleSendModal = true
                      },
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "d-flex align-items-center flex-gap-1" },
                      [
                        _c("CIcon", { attrs: { name: "cil-send" } }),
                        _vm._v(" Schedule send "),
                      ],
                      1
                    ),
                  ]
                ),
              ],
              1
            ),
            _c(
              "CButton",
              {
                staticClass: "ml-auto",
                attrs: { variant: "ghost", color: "danger" },
                on: { click: _vm.resetForm },
              },
              [_c("u", [_vm._v("Reset Form")])]
            ),
          ],
          1
        ),
      ]),
      _c(
        "CModal",
        {
          staticClass: "schedule-send-modal",
          attrs: {
            title: "Pick date & time",
            color: "white",
            show: _vm.showScheduleSendModal,
          },
          on: {
            "update:show": function ($event) {
              _vm.showScheduleSendModal = $event
            },
          },
          scopedSlots: _vm._u([
            {
              key: "footer",
              fn: function () {
                return [
                  _c(
                    "CButton",
                    {
                      attrs: { color: "link" },
                      on: {
                        click: function ($event) {
                          _vm.showScheduleSendModal = false
                        },
                      },
                    },
                    [_vm._v("Cancel")]
                  ),
                  _c(
                    "CButton",
                    {
                      attrs: {
                        disabled:
                          _vm.scheduledTime === null ||
                          _vm.momentScheduledTime.isSameOrBefore(new Date()),
                        color: "primary",
                        shape: "pill",
                      },
                      on: { click: _vm.scheduleSend },
                    },
                    [_vm._v(" Schedule send ")]
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            "div",
            { staticClass: "d-flex" },
            [
              _c("v-date-picker", {
                attrs: {
                  mode: "dateTime",
                  is24hr: "",
                  "minute-increment": 5,
                  "disabled-dates": {
                    end: new Date().setDate(new Date().getDate() - 1),
                  },
                },
                model: {
                  value: _vm.scheduledTime,
                  callback: function ($$v) {
                    _vm.scheduledTime = $$v
                  },
                  expression: "scheduledTime",
                },
              }),
              _vm.scheduledTime
                ? _c("div", { staticClass: "lead p-3 w-100" }, [
                    _c(
                      "span",
                      { staticClass: "d-block border-bottom pb-2 mb-4" },
                      [
                        _vm._v(
                          _vm._s(_vm.momentScheduledTime.format("MMM Do YY"))
                        ),
                      ]
                    ),
                    _c(
                      "span",
                      { staticClass: "d-block border-bottom pb-2 mb-4" },
                      [_vm._v(_vm._s(_vm.momentScheduledTime.format("LT")))]
                    ),
                    _vm.momentScheduledTime.isSameOrBefore(new Date())
                      ? _c("span", { staticClass: "d-block text-danger" }, [
                          _vm._v("Invalid date & time"),
                        ])
                      : _c("span", { staticClass: "d-block" }, [
                          _vm._v(
                            "* " + _vm._s(_vm.momentScheduledTime.fromNow())
                          ),
                        ]),
                  ])
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }